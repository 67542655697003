import logo from './resources/NDC.png';
import Socials from './components/Socials/Socials';
import './App.css';

function App() {
  return (
    <body className="App">
      <div className="app-header">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
      <h1>NDC is undergowing some changes</h1>
      <span>But you can still check out all our home grown merch at <a href="https://www.etsy.com/shop/NorthtownDesignCo" style={{color:'#FFFFFF'}}> our Etsy store</a>!</span>
      <Socials />
    </body>
  );
}

export default App;
