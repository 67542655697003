import React, { FC } from 'react';
import Facebook from '../../resources/Facebook.svg';
import IG from '../../resources/IG.svg';
import Twitter from '../../resources/Twitter.svg';
import email from '../../resources/email.svg';
import './Socials.css'

interface SocialsProps {}

const Socials: FC<SocialsProps> = () => (
  <div className='Socials' data-testid="Socials">
    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/NKCDesignCo/">
      <img src={Facebook} className="social-icon" alt="Facebook Icon" />
    </a>
    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/nkcdesignco/">
      <img src={IG} className="social-icon" alt="Instagram Icon" />
    </a>

    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/NKCDesignCo">
      <img src={Twitter} className="social-icon" alt="Twitter Icon" />
    </a>

    <a href="mailto:info@northtowndesignco.com.com" target="_blank" rel="noopener noreferrer">
      <img src={email} className="social-icon" alt="Emai Icon" />
    </a>

  </div>
);

export default Socials;
